<template>
  <modal name="add-experience" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '40%'"
    :scrollable="true">
    <div class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white">
      <h1 class="text-lg">
        {{ $t("cv.profile.workExperience.components.add.add") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form @submit.prevent="isEdit ? add() : save()" class="p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20" novalidate>
      <div class="space-y-4 pb-3">
        <multiSelectVue :placeholder="$t('cv.profile.workExperience.components.add.companyNamePlacholder')"
          :label="$t('cv.profile.workExperience.components.add.companyName')" ref="companyRef" v-model="companyName"
          :required="true" :getOptions="getCorporation" />

        <multiSelectVue :placeholder="$t('cv.profile.workExperience.components.add.position')"
          :label="$t('cv.profile.workExperience.components.add.positionPlacholder')" ref="positionRef"
          v-model="PositionTitle" :required="true" :getOptions="getPosition" />

        <div class="grid grid-cols-2 gap-2">
          <customInput v-model="periotStart" type="date" :required="true" :is-error="true" ref="periotStart"
            :placeholder="$t('cv.profile.workExperience.components.add.startPlacholder')
              " :title="$t('cv.profile.workExperience.components.add.start')" />
          <customInput v-model="periotEnd" type="date"
            :placeholder="$t('cv.profile.workExperience.components.add.finish')"
            :title="$t('cv.profile.workExperience.components.add.finish')" />
        </div>
        <customInput v-model="doesItContinue" type="select" :selectList="[
          {
            value: 1,
            name: $t('cv.profile.workExperience.components.add.continues'),
          },
          {
            value: 2,
            name: $t('cv.profile.workExperience.components.add.completed'),
          },
        ]" :placeholder="$t('cv.profile.workExperience.components.add.levelPlacholder')
          " :title="$t('cv.profile.workExperience.components.add.level')" />

        <customInput v-model="explanation" type="textarea" max="500" :placeholder="$t('cv.profile.workExperience.components.add.explanationPlacholder')
          " :title="$t('cv.profile.workExperience.components.add.explanation')" />

        <requiredField />

        <div class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0">
          <div class="w-full">
            <buttonItem :load="load" :name="$t('cv.profile.workExperience.components.add.save')" buttonClass="w-full" />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import moment from "moment";
import requiredField from "@/components/requiredField.vue";
import { utility, workExperience, utilityPosition } from "@/networking/urlmanager.js";

export default {
  name: "addProject",
  props: ["isEdit"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,

      companyName: null,
      PositionTitle: null,

      periotStart: "",
      periotEnd: "",
      doesItContinue: 0,
      explanation: "",
    };
  },
  methods: {
    show() {
      this.$modal.show("add-experience");
    },
    hide() {
      this.$modal.hide("add-experience");
    },
    save() {
      this.load = true;

      this.checkErrors();

      let format = this.getDateFormatter();

      this.$store.commit("addExperience", {
        company: this.companyName,
        position: this.PositionTitle,
        duration: format.date,
        formatDate: format.date,
        description: this.explanation.replace(/[\n\t]+/g, ""),
        doesItContinue: this.doesItContinue,
      });

      this.doesItContinue = 0;
      this.companyName = "";
      this.PositionTitle = "";
      this.periotStart = "";
      this.periotEnd = "";
      this.explanation = "";

      if (!this.isEdit) {
        this.successMessage();
      }

      this.load = false;
      if (this.isEdit) {
        this.$emit("refresh", true);
      }
      this.hide();
    },
    add() {
      this.load = true;
      this.checkErrors();

      let format = this.getDateFormatter();

      const experince = this.$store.state.extractDetail.workexperience;

      this.axios
        .post(workExperience.add, {
          cvId: this.$route.params.id,
          list: {
            company: this.companyName,
            position: this.PositionTitle,
            duration: format.date,
            formatDate: format.date,
            description: this.explanation,
            doesItContinue: this.doesItContinue,
          },
          experinceList: JSON.stringify(experince),
        })
        .then((res) => {
          this.successMessage(res.data.message);
          this.save();
          this.load = false;
          this.hide();
        })
        .catch((err) => {
          this.authController(err);
          this.load = false;
        }).finally(() => {
          this.load = false;
        });

    },
    async getPosition() {
      const response = await this.axios.get(utilityPosition.getAll);
      return response.data.data;
    },
    async getCorporation() {
      const response = await this.axios.get(utility.getCorporationAll);
      return response.data.data;
    },
    getDateFormatter() {
      if (!this.periotStart) return { date: 0, experience: 0 };

      const startDate = moment(this.periotStart).format("YYYY.MM.DD");
      const endDate = this.periotEnd
        ? moment(this.periotEnd).format("YYYY.MM.DD")
        : "Present";

      return { date: `${startDate} - ${endDate}` };
    },
    checkErrors() {
      this.$refs.companyRef.validate();
      this.$refs.positionRef.validate();
      this.$refs.periotStart.validateInput();
    },
  },
};
</script>
