<template>
  <sideModal
    ref="preInterviewFilters"
    modalClass="w-4/12 max-xl:w-10/12 max-sm:w-full"
    :isHeader="true"
    :headerTitle="$t('project.detail.filterPanel.title')"
  >
    <template v-slot:content>
      <div class="overflow-y-auto scroltype h-[85vh] mt-2 p-2 max-sm:p-5">
        <form @submit.prevent="getFilter" class="p-2">
          <p class="text-sm pb-1">
            -
            {{ $t("project.detail.filterPanel.state") }}
          </p>
          <select
            name=""
            id=""
            class="w-full border-2 border-344CA0 p-2 rounded mb-5 text-sm"
            v-model="stateId"
          >
            <option
              v-for="(item, index) in projectUserState"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>

          <div v-for="(item, index) in List" :key="index">
            <div class="mb-4 pb-5 border-b border-gray-300" v-if="!(item.questionType <= 1)" >
              <p class="text-sm pb-4">- {{ formatText(item.question) }}</p>

              <div
                v-if="item.questionType == 2"
                class="flex justify-between gap-5"
              >
                <input
                  v-model="item.min"
                  type="number"
                  placeholder="min"
                  class="w-full border-2 border-344CA0 p-2 rounded text-344CA0"
                />
                <input
                  v-model="item.max"
                  type="number"
                  placeholder="max"
                  class="w-full border-2 border-344CA0 p-2 rounded text-344CA0"
                />
              </div>
              <input
                v-if="item.questionType == 3"
                type="number"
                v-model="item.number"
                class="w-full border-2 border-344CA0 p-2 rounded text-344CA0"
              />
              <div
                v-if="item.questionType == 4"
                class="flex justify-between gap-5 text-sm"
              >
                <select
                  v-model="item.yesNo"
                  name=""
                  id=""
                  class="w-full border-2 border-344CA0 p-2 rounded text-sm"
                >
                  <option value="0">
                    {{ $t("project.detail.filterPanel.choose") }}
                  </option>
                  <option value="1">
                    {{ $t("project.detail.filterPanel.yes") }}
                  </option>
                  <option value="2">
                    {{ $t("project.detail.filterPanel.no") }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <buttonItem
            :load="load"
            buttonClass="w-full font-bold hover:bg-opacity-90 mt-3"
          />
        </form>
      </div>
    </template>
  </sideModal>
</template>

<script>
import "vue-slider-component/theme/default.css";
import { projectInterview } from "@/networking/urlmanager";
import buttonItem from "@/components/button.vue";

export default {
  components: {
    buttonItem,
  },
  data() {
    return {
      isOpen: false,
      load: false,
      count: 0,

      search: "",
      currentPage: 0,
      totalCount: "",
      pageCount: 1,

      List: [],
      stateId: 0,

      projectUserState: [
        {
          id: 0,
          name: this.$t("state.all"),
          active: false,
        },
        {
          id: 1,
          name: this.$t("state.noAction"),
          active: false,
        },
        {
          id: 2,
          name: this.$t("state.waiting"),
          active: false,
        },
        {
          id: 3,
          name: this.$t("state.continues"),
          active: false,
        },
        {
          id: 4,
          name: this.$t("state.completed"),
          active: false,
        },
        {
          id: 6,
          name: this.$t("state.didNotAgree"),
          active: false,
        },
        {
          id: 5,
          name: this.$t("state.decisionHurdle"),
          active: false,
        },
        {
          id: 7,
          name: this.$t("state.notEnough"),
          active: false,
        },
        {
          id: 8,
          name: this.$t("state.shortlisted"),
          active: false,
        },
      ],
    };
  },
  methods: {
    updateExperienceRange(value, index) {
      this.List[index].min = value[0];
      this.List[index].max = value[1];
    },
    toggleSidebar() {
      this.$refs.preInterviewFilters.show();
      this.getAll();
    },
    async getAll() {
      this.load = true;
      try {
        const response = await this.axios.get(projectInterview.getAll, {
          params: {
            page: this.currentPage,
            search: this.search,
            projectId: this.$route.query.id,
          },
        });
        const detail = response.data.data.data;
        this.List = await Promise.all(
          detail.map(async (item) => {
            switch (item.questionType) {
              case 1:
                return { ...item, text: "" };
              case 2:
                return { ...item, min: 0, max: 0 };
              case 3:
                return { ...item, number: 0 };
              case 4:
                return { ...item, yesNo: 0 };
              case 5:
                return { ...item, date: "2022-03-12" };
              default:
                return item;
            }
          })
        );
        this.load = false;
      } catch (error) {
        console.error(error);
        this.load = false;
        this.authController(error);
      }
    },
    getFilter() {
      this.load = true;
      this.axios.post(projectInterview.getFilter, {
          list: JSON.stringify(this.List),
          page: 0,
          projectId: this.$route.query.id,
          stateId: this.stateId,
        })
        .then((res) => {
          this.load = false;
          this.$refs.preInterviewFilters.close();
          this.stateId = 0;
          this.$emit("filterAll", res.data.data);
          this.List = [];
        })
        .catch((err) => {
          console.log(err);
          this.load = false;
          this.authController(err);
        });
    },
  },
};
</script>