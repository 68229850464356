<template>
  <div class="my-10 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4 max-sm:my-8">
    <div class="flex gap-5 max-sm:flex-col">
      <div>
        <button
          class="px-8 py-2 bgcol h-11 text-sm max-sm:w-[182px] text-white rounded"
          @click="$refs.addUser.show()"
          data-v-step="0"
        >
          <i class="fas fa-plus mr-2"></i>
          {{ $t("user.index.add") }}
        </button>
      </div>
    </div>

    <addUser ref="addUser" @refresh="getAll()" />
    <editUser ref="editUser" @refresh="getAll()" :detail="selectedItem" />

    <div class="tableOverflow scroltypey mt-5" data-v-step="1">
      <div class="tableWidth">
        <div class="tableContainer">
          <div class="tableHead">
            <ul class="tableUl">
              <li class="w-1/12">#</li>
              <li class="w-3/12">{{ $t("user.index.table.name") }}</li>
              <li class="w-2/12">{{ $t("user.index.table.authority") }}</li>
              <li class="w-3/12">{{ $t("user.index.table.email") }}</li>
              <li class="w-3/12 flex justify-center">
                <div class="w-[300px] tableSearch">
                  <input
                    v-model="search"
                    @keypress.enter="getSearch()"
                    type="text"
                    class="h-full w-full outline-none pl-3 placeholder:textcol font-light rounded-md text-black pr-16"
                    :placeholder="$t('user.index.table.search')"
                  />
                  <button class="px-3 textcol absolute right-0">
                    <i
                      @click="getSearch()"
                      class="fa-solid fa-magnifying-glass"
                    ></i>
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div class="tableBody scroltype">
            <div class="tableBodyContainer">
              <ul
                v-for="(item, index) in List"
                :key="index"
                class="tableBodyUl"
              >
                <li
                  @click="() => ((selectedItem = item), $refs.editUser.show())"
                  class="cursor-pointer w-1/12 py-2.5 pl-1"
                >
                  {{ currentPage * 15 + (index + 1) }}
                </li>
                <li
                  @click="() => ((selectedItem = item), $refs.editUser.show())"
                  class="cursor-pointer w-3/12 py-2.5"
                >
                  {{ formatText(item.firstName + " " + item.lastName) }}
                </li>
                <li
                  @click="() => ((selectedItem = item), $refs.editUser.show())"
                  class="cursor-pointer w-2/12 py-2.5"
                >
                  {{
                    item.authority == 1
                      ? $t("user.index.table.executive")
                      : $t("user.index.table.user")
                  }}
                </li>
                <li
                  @click="() => ((selectedItem = item), $refs.editUser.show())"
                  class="cursor-pointer w-3/12 py-2.5 text-justify pr-5"
                >
                  {{ item.email }}
                </li>
                <li class="cursor-pointer w-3/12 flex gap-4 justify-end px-5">
                  <button
                    data-v-step="2"
                    @click="
                      () => ((selectedItem = item), $refs.editUser.show())
                    "
                    class="flex items-center h-full"
                  >
                    <div class="relative group flex justify-center">
                      <i class="fa-solid fa-pen-to-square text-base"></i>
                      <tooltip
                        :title="$t('user.index.table.editTooltip')"
                        tipClass="z-50 !top-6 !w-[130px]"
                        :top="true"
                      />
                    </div>
                  </button>
                  <button
                    data-v-step="3"
                    @click="deleteItem(item)"
                    class="flex items-center h-full"
                  >
                    <div class="relative group flex justify-center">
                      <i class="fa-solid fa-trash-can text-base"></i>
                      <tooltip
                        :title="$t('user.index.table.deletTooltip')"
                        tipClass="z-50 !top-6 !w-[130px] -right-7 "
                        :top="true"
                        rotateClass="ml-[58px]"
                      />
                    </div>
                  </button>
                </li>
              </ul>
              <tableLoader :load="load" :length="List.length" colspan="7" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <tour
      :steps="steps"
      :moduleId="1"
      v-if="!$store?.state?.userData?.tourModuleId?.includes(1)"
    />

    <pagination
      v-model="currentPage"
      :pageCount="pageCount"
      :totalRowCount="totalCount"
    />
  </div>
</template>
<script>
import addUser from "./components/addUser.vue";
import editUser from "./components/editUser.vue";
import pagination from "@/components/pagination.vue";
import tableLoader from "@/components/table-loader.vue";

import { users } from "@/networking/urlmanager";
import { userstour } from "@/tourmodel/tour.js";
import moment from "moment";
import "moment/locale/tr";
import tour from "@/components/tour.vue";
import tooltip from "@/components/tooltip.vue";
export default {
  name: "users-page",
  components: {
    addUser,
    editUser,
    tableLoader,
    pagination,
    tour,
    tooltip,
  },
  data() {
    return {
      steps: userstour,

      load: false,

      search: "",
      currentPage: 0,
      totalCount: "",
      pageCount: 1,

      rowId: 0,

      selectedItem: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        authority: "",
      },
      List: [],
    };
  },
  methods: {
    getSearch() {
      this.currentPage = 0;
      this.getAll();
    },
    getAll() {
      this.load = true;
      this.List = [];
      this.axios
        .get(
          users.getAll + "?page=" + this.currentPage + "&search=" + this.search,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          const { totalCount, detail, pageCount } = res.data.data;
          this.totalCount = totalCount;
          this.pageCount = pageCount;
          this.List = detail;
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    deleteItem(item) {
      this.verifiedMessage().then((result) => {
        if (result.isConfirmed) {
          this.axios
            .post(
              users.delete,
              {
                rowId: item.id,
                fullname: item.firstName + " " + item.lastName,
              },
              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then((res) => {
              this.successMessage(res.data.message);
              this.getAll();
            })
            .catch((err) => {
              const errorMessage = err.response
                ? err.response.data.message
                : err.message;
              this.authController(errorMessage);
            });
        }
      });
    },
  },
  created() {
    this.getAll();
  },
  /* BU ALAN DİL DESTEĞİ EKLENMEDİ  TASARIMDA NEREDE OLDUĞU BULUNAMADI...*/
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LL");
      else return $t("noDate");
    },
  },
  watch: {
    currentPage() {
      this.getAll();
    },
    search: function (newSearch) {
      if (newSearch.length === 0) {
        this.getAll();
      }
    },
  },
};
</script>
