<template>
    <label :key="item.id" class="flex items-center gap-2" :for="`item${item.id}`" @dblclick="startEdit(item.id)">
        <span v-if="item.id !== listItemEditIndex" class="text-gray-600 font-medium">
            {{ item.weightedScore }}
        </span>

        <input ref="inputRef" v-else type="text" :id="`item${item.id}`"
            :value="listItemIsLoad ? listItemEditValue : item.weightedScore" 
            @input="handleInput"
            class="bg-transparent border border-gray-300 rounded-md text-sm px-2 m-0 h-5 w-12 resize-none" 
            @blur="handleBlur"
            @keydown.enter="handleEdit(item.id, item.weightedScore)"
            :disabled="listItemIsLoad"
            @keydown.esc="cancelEdit" />

        <button type="button" class="text-gray-400 hover:text-gray-600" 
            @mousedown.prevent 
            @click="handleButtonClick(item.id, item.weightedScore)">
            <i v-if="listItemEditIndex !== item.id" class="fas fa-edit" />
            <i v-else-if="listItemEditIndex === item.id && listItemIsLoad" class="fas fa-spinner animate-spin" />
            <i v-else class="fas fa-check" />
        </button>
    </label>
</template>

<script>
import { projectInterview } from "@/networking/urlmanager";

export default {
    name: 'EditableScore',
    props: {
        item: {
            type: Object,
            required: true
        },
        List: {
            type: Array,
            required: true
        },
        noPost: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            listItemEditIndex: -1,
            listItemEditValue: null,
            listItemIsLoad: false,
            mutableList: [...this.List], // Create a copy of the list
        };
    },
    methods: {
        startEdit(id) {
            this.listItemEditIndex = id;
            this.listItemEditValue = this.item.weightedScore;
            this.$nextTick(() => {
                if (this.$refs.inputRef) {
                    this.$refs.inputRef.focus();
                }
            });
        },

        handleInput(event) {
            this.listItemEditValue = event.target.value;
        },

        handleBlur() {
            if (!this.listItemIsLoad) {
                this.listItemEditIndex = -1;
            }
        },

        cancelEdit() {
            this.listItemEditIndex = -1;
            this.listItemEditValue = null;
        },

        handleButtonClick(id, val) {
            if (this.listItemEditIndex !== id) {
                this.startEdit(id);
            } else {
                this.handleEdit(id, val);
            }
        },

        async handleEdit(id, originalValue) {
            const itemIndex = this.mutableList.findIndex((item) => item.id === id);

            if (!this.listItemEditValue || this.listItemEditValue === originalValue) {
                this.listItemEditIndex = -1;
                return;
            }

            if (Number.isNaN(Number(this.listItemEditValue))) {
                this.listItemEditIndex = -1;
                this.listItemIsLoad = false;
                return;
            }

            try {
                this.listItemIsLoad = true;

                if (!this.noPost) {
                    const response = await this.axios.post(projectInterview.chanceQuestionWeight, {
                        projectId: this.$route.query.id,
                        rowId: this.mutableList[itemIndex].id,
                        weightedScore: this.listItemEditValue
                    });

                    this.successMessage(response.data.message);
                }

                // Update the list item
                this.mutableList[itemIndex].weightedScore = this.listItemEditValue;
                this.$emit("updateList", [...this.mutableList]);

            } catch (err) {
                this.authController(err);
            } finally {
                this.listItemIsLoad = false;
                this.listItemEditIndex = -1;
                this.listItemEditValue = null;
            }
        },
    },
    watch: {
        List: {
            handler(newList) {
                this.mutableList = [...newList];
            },
            deep: true
        }
    }
};
</script>