<template>
  <div class="w-full flex max-sm:flex-wrap mt-10">
    <div class="w-full max-sm:w-full">
      <div class="flex justify-between max-sm:flex-col-reverse">
        <h4 class="text-lg tracking-wider textcol font-semibold">
          <span class="border-t-2 pt-1 border-[#344CA0]">{{
            $t("cv.profile.workExperience.index.title")
          }}</span>
        </h4>


        <addItem ref="addModal" :isEdit="isEdit" @refresh="getAll()" />
        <editItem
          ref="editItem"
          :isEdit="isEdit"
          @refresh="getAll()"
          :Detail="selected"
          :index="index"
          @loadChance="loading = !loading"
        />

        <div
          class="flex justify-end items-center gap-5 max-sm:justify-between max-sm:mb-6"
        >
          <div class="relative group flex justify-center">
            <button
              @click="$refs.addModal.show()"
              class="w-20 py-2 text-base rounded text-black"
            >
              <i class="fas fa-plus mr-1"></i>
              {{ $t("cv.profile.workExperience.index.add") }}
            </button>
            <tooltip
              :title="$t('cv.profile.workExperience.index.toolTip')"
              tipClass=" bottom-8 max-sm:ml-2 text-center"
            />
          </div>
        </div>
      </div>
      <div
        v-if="isContent && !loading"
        class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 relative"
      >
        <draggable
          v-model="$store.state.extractDetail.workexperience"
          @change="
            $store.commit(
              'setWorkExperience',
              $store.state.extractDetail.workexperience
            )
          "
          @start="drag = true"
          @end="drag = false"
        >
          <div
            class="w-full border-b border-b-[#344ba045] pb-4 border-dotted avoid-page-break"
            v-for="(item, index) in $store.state.extractDetail.workexperience"
            :key="index"
          >
            <card
              :img="require('@/assets/cv/carbon_inventory-management.svg')"
              :rowId="item.id"
              :title="item.company"
              :sub="item.position"
              :third="item.duration"
              :four="item.description"
              :five="item.doesItContinue ? Number(item.doesItContinue) === 1 ? $t('state.continues') : $t('state.completed')  : ''"
              :index="index"
              deleteCommitName="deleteEducation"
              :isDraggable="true"
              @deleteItem="!isEdit ? deleteStoreItem(index) : deleteItem(item)"
              @editItem="editItem(item, index)"
            />
          </div>
        </draggable>
      </div>

      <dataLoader
        :load="loading"
        :isContent="isContent"
        :title="$t('cv.profile.workExperience.index.dataLoader')"
      />
    </div>
  </div>
</template>

<script>
import card from "../components/card.vue";
import { workExperience } from "@/networking/urlmanager.js";
import draggable from "vuedraggable";
import tooltip from "@/components/tooltip.vue";
import addItem from "./components/add.vue";
import editItem from "./components/edit.vue";
import dataLoader from "../components/dataLoader.vue";

export default {
  name: "workExperience",
  props: ["load", "isEdit"],
  components: {
    card,
    draggable,
    tooltip,
    addItem,
    editItem,
    dataLoader,
  },
  data() {
    return {
      loading: false,
      selected: "",
      index: "",
    };
  },
  methods: {
    getAll() {
      this.loading = true;
      this.axios
        .get(workExperience.getAll + "?cvId=" + this.$route.params.id)
        .then((res) => {
          this.$store.commit("setWorkExperience", res.data.data);
        })
        .catch((err) => {
          console.log(err);
          this.authController(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteItem(item) {
      this.verifiedMessage().then((result) => {
        if (result.isConfirmed) {
          let experince = this.$store.state.extractDetail.workexperience;

          this.axios
            .post(workExperience.delete, {
              rowId: item.id,
              cvId: this.$route.params.id,
              experinceList: JSON.stringify(experince),
            })
            .then((res) => {
              this.successMessage(res.data.message);
              this.getAll();
            })
            .catch((err) => {
              const errorMessage = err.response
                ? err.response.data.message
                : err.message;
              this.authController(errorMessage);
            });
        }
      });
    },
    deleteStoreItem(index) {
      this.verifiedMessage().then((acc) => {
        if (acc.isConfirmed) {
          this.$store.commit("deleteExperience", index);
        }
      });
    },
    editItem(val, index) {
      this.$refs.editItem.show();
      this.selected = val;
      this.index = index;
    },
  },
  computed: {
    isContent() {
      if (Array.isArray(this.$store.state.extractDetail.workexperience))
        if (this.$store.state.extractDetail.workexperience.length > 0)
          return true;
        else return false;
      else return false;
    },
  },
};
</script>
