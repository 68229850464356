<template>
  <div class="signIn flex">
    <info />
    <div
      class="h-screen w-5/12 px-24 max-md:w-full max-lg:px-10 max-sm:px-6 flex max-lg:flex-col max-lg:justify-center items-center"
    >
      <!-- <logobutton /> -->
      <div
        class="w-full max-lg:h-screen max-lg:flex max-lg:flex-col max-lg:justify-center"
      >
        <img class="mx-auto w-72" src="@/assets/global/fullLogo.png" alt="" />
        <p class="text-center mt-10 text-sm">
          {{ $t("auth.signIn.message") }}
        </p>

        <form @submit.prevent="onSubmit" class="mt-10">
          <customInput
            v-model="email"
            type="email"
            autocomplete="username"
            min="5"
            max="40"
            :required="true"
            :placeholder="$t('auth.signIn.email')"
          />
          <customInput
            v-model="password"
            :type="!passwordState ? 'password' : 'text'"
            min="4"
            max="30"
            autocomplete="current-password"
            :required="true"
            :placeholder="$t('auth.signIn.password')"
            :passwordState="passwordState"
            @hidenState="(val) => (passwordState = val)"
          />

          <div class="flex justify-between my-5">
            <div class="flex items-center text-base gap-1">
              <input
                class="bg-gray-500 w-5 h-5 mr-2"
                type="checkbox"
                id="rememberMe"
                v-model="rememberMe"
              /><label
                for="rememberMe"
                class="font-semibold text-gray-700 text-sm"
              >
                {{ $t("auth.signIn.rememberMe") }}</label
              >
            </div>
            <router-link
              to="/set/password"
              class="text-sm border-b border-transparent transition-all hover:border-gray-800 font-semibold text-gray-700"
            >
              {{ $t("auth.signIn.forgotPassword") }}
            </router-link>
          </div>
          <asyncButton
            text="Giriş Yap"
            :load="load"
            buttonClass=" !w-full"
            :loadText="$t('auth.signIn.loadText')"
          />
        </form>
      </div>
      <div
        class="flex flex-col items-center justify-center my-10 w-full px-16 md:hidden"
      >
        <p
          class="text-xs text-center cursor-pointer mb-2"
          @click="$router.push('/privacy-policy')"
        >
          {{ $t("auth.signIn.privacyPolicy") }}
        </p>
        <p class="text-xs text-center">
          {{ $t("auth.signIn.copyright") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import info from "./components/information.vue";
import customInput from "@/components/diffInput.vue";
import asyncButton from "@/components/button.vue";

// networking
import { auth } from "@/networking/urlmanager";
import axios from "axios";

export default {
  name: "signIn",
  components: {
    info,
    // logobutton,
    customInput,
    asyncButton,
  },
  data() {
    return {
      email: this.$store.state.email,
      password: "",
      load: false,
      rememberMe: false,
      passwordState: false,
      failedAttempt: 0,
      isError: false,
      errorMessage: "",
    };
  },
  methods: {
    async onSubmit() {
      this.load = true;
      try {
        const token = await this.$recaptcha("login");
        await this.signIn(token);
      } catch (error) {
        this.load = false;
        this.authController(error);
      }
    },
    signIn(token) {
      this.isError = false;
      const recaptcha = this.$recaptchaInstance;
      if (/^[^@]+@\w+(\.\w+)+\w$/.test(this.email) && this.password) {
        axios.post(auth.signIn, {
            email: this.email,
            password: this.password,
            token: token,
            isTest:1
          })
          .then((res) => {
            if (this.rememberMe) {
              this.$store.commit("rememberMe", this.email);
            }

            recaptcha.hideBadge();

            let lang = res.data.data?.language?.toLowerCase() || "en";
            this.$i18n.locale = lang;
            this.$store.commit("setLocale", res.data.data?.language?.toLowerCase() || "en");

            this.load = false;
            this.$store.commit("signIn", {
              ...res.data.data,
              tourModuleId: this.listFormatController(res.data.data.tourModuleId)
            });
            this.$router.push("/project");
          })
          .catch((err) => {
            this.notificationAlert(err,"error")
            this.load = false;
          });
        // }
      } else {
        this.warningMesage(this.$t("auth.signIn.warningMesage"));
        this.load = false;
      }
    },
  },
  created() {
    if (this.$store.state.userData.isAuth) this.$router.push("/project");
    setTimeout(() => {
      this.$recaptchaInstance ? this.$recaptchaInstance.showBadge() : "";
    }, 50);
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
