<template>
  <div class="my-10 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4">
    <div class="flex justify-between items-center">
      <router-link to="/project">
        <h2 class="text-xl textcol font-semibold mt-5">
          <i class="fa-sharp fa-solid fa-arrow-left"></i>
          {{ $t("project.detail.positions") }}
        </h2>
      </router-link>
      <p class="text-xl textcol font-semibold mt-5 capitalize">
        {{ formatText($route.query.projectName) }}
      </p>
    </div>

    <recommendedModal ref="recommendedModal" @refresh="getAll()" />
    <preInterviewDetail
      ref="preInterviewDetail"
      :Detail="selected"
      :projectId="$route.query.id"
      @refresh="getAll()"
    />
    <filtering ref="filtering" :data="List" @filterAll="(r) => getFilter(r)" />
    <interviewQuestion ref="interviewQuestion" @refresh="getAll()" />
    <addEmail ref="addEmail" @refresh="getAll()" :cvId="selected.cvId" />
    <interviewPanel ref="interviewPanel" @refresh="getAll()" />
    <shortListPanel ref="shortListRef" @refresh="getAll()" />

    <div class="flex my-6 justify-between max-sm:flex-wrap max-sm:w-full">
      <div class="flex gap-5 max-sm:w-full max-sm:flex-wrap">
        <asyncButton
          data-v-step="4"
          :text="$t('project.detail.startInterview')"
          :load="loading"
          @click="createQuesion()"
          :loadText="$t('project.detail.interviewProcessLoad')"
          buttonClass="text-sm max-sm:!w-full max-sm:!justify-start"
          :imgSrc="require('@/assets/img/start-the-conversation.svg')"
          :imgHidden="false"
          containerClass="max-sm:w-full"
        />

        <asyncButton
          data-v-step="4"
          :text="$t('project.detail.recommendedResumes')"
          @click="$refs.recommendedModal.show()"
          buttonClass="text-sm max-sm:!w-full max-sm:!justify-start"
          :imgSrc="require('@/assets/img/recommended-resumes.svg')"
          :imgHidden="false"
          containerClass="max-sm:w-full"
          containerBackground="bg-gray-100 !text-black !shadow  justify-center gap-3 !w-full"
        />

        <asyncButton
          :text="$t('project.detail.shortList')"
          @click="$refs.shortListRef.toggleSidebar()"
          buttonClass="text-sm max-sm:!w-full max-sm:!justify-start"
          :imgSrc="require('@/assets/img/recommended-resumes.svg')"
          :imgHidden="false"
          containerClass="max-sm:w-full"
          containerBackground="bg-gray-100 !text-black !shadow  justify-center gap-3 !w-full"
        />
      

        <button @click="$refs.interviewPanel.toggleSidebar()" class="py-2 px-8 flex items-center gap-2 bg-blue-900 hover:text-base hover:shadow-xl rounded-t text-white text-sm font-medium cursor-pointer shadow-xl transform rotate-0 fixed bottom-0 z-50 h-10 right-5 transition-all ease-out md:transform md:-rotate-90 md:origin-bottom-right md:top-[90px] md:bottom-auto md:right-0 md:h-auto">
          <span class="animate-pulse flex gap-3 items-center">
            <!-- <i class="fas fa-list cursor-pointer" aria-hidden="true"></i> -->
            <img src="@/assets/project/aiInterview.png" alt="" class="w-6" />
            {{ $t("project.detail.interviewProcess") }}
          </span>
        </button>
      </div>

      <asyncButton
          data-v-step="5"
          :text="$t('project.detail.addQuestion')"
          @click="$refs.interviewQuestion.toggleSidebar()"
          buttonClass="text-sm max-sm:!w-full max-sm:!justify-start"
          :imgSrc="require('@/assets/project/conversation.png')"
          :imgHidden="false"
          containerClass="max-sm:w-full"
          containerBackground="flex bg-[#878DA5] text-white shadow px-4 py-1 max-sm:w-full max-sm:py-3 max-sm:mt-3 rounded items-center justify-start text-sm"
        />

    </div>

    <div class="tableOverflow scroltypey">
      <div class="tableWidth">
        <div class="tableContainer">
          <div class="tableHead">
            <ul class="tableUl">
              <li class="w-1/12">
                <input type="checkbox" @change="(val) => selectAll(val)" />
              </li>
              <li class="w-3/12">{{ $t("project.detail.table.ressume") }}</li>
              <li class="w-3/12">{{ $t("project.detail.table.position") }}</li>
              <li class="w-5/12 flex justify-end">
                <div class="w-[300px] tableSearch relative">
                  <input
                    v-model="search"
                    @keypress.enter="getSearch()"
                    type="text"
                    class="h-full w-full outline-none pl-3 placeholder:textcol font-light rounded-md text-black pr-16"
                    :placeholder="$t('project.detail.table.search')"
                  />
                  <button class="px-3 textcol absolute right-0">
                    <i
                      @click="getSearch()"
                      class="fa-solid fa-magnifying-glass"
                    ></i>
                  </button>
                  <button
                    @click="$refs.filtering.toggleSidebar()"
                    class="absolute right-5"
                  >
                    <img
                      data-v-step="8"
                      src="@/assets/cv/filteredit.svg"
                      alt=""
                      class="w-5 mr-5 object-contain"
                    />
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div class="tableBody scroltype">
            <div class="tableBodyContainer">
              <ul v-for="(item, index) in List" :key="index" class="tableBodyUl">
                <li class="w-1/12 py-2.5">
                  <!-- :disabled="
                      statusFilter(item).id == 4 ||
                      statusFilter(item).id == 7 ||
                      statusFilter(item).id == 8
                    " -->

                  <input
                    v-if="isEmail(item)"
                    :disabled="
                      item.stateId == 7 ||
                      item.stateId == 8 ||
                      item.stateId == 9
                    "
                    type="checkbox"
                    v-model="checkedList"
                    :name="index"
                    :id="index"
                    :value="index"
                    class="mr-2"
                  />

                  <div v-else class="relative group">
                    <i class="fa-solid fa-xmark text-red-600 text-lg"></i>
                    <tooltip
                      :title="$t('project.detail.table.notEmail')"
                      tipClass="z-10 w-[160px] !top-6 -ml-2 text-center"
                      rotateClass="-ml-32"
                      :top="true"
                    />
                  </div>
                </li>
                <li
                  @click="() => $router.push('/cv/profile/' + item.cvId)"
                  class="cursor-pointer select-none w-3/12 py-2.5"
                >
                  {{ formatText(item.fullname) }}
                 
                </li>
                <li
                  @click="() => $router.push('/cv/profile/' + item.cvId)"
                  class="cursor-pointer select-none w-3/12 py-2.5"
                >
                  {{ formatText(item.position) }}
                </li>

                <li
                  class="cursor-pointer select-none w-5/12 flex gap-6 justify-between px-3 items-center"
                >
                  <div
                    class="w-4/12"
                    @click="
                      () =>
                        (selected = item) &&
                        (isEmail(item)
                          ? $router.push('/cv/profile/' + item.cvId)
                          : $refs.addEmail.show())
                    "
                  >
                    <label v-if="isEmail(item)" class="cursor-pointer">
                      <!-- :class="{
                        'text-purple-500': statusFilter(item).id == 2,
                        'text-orange-400': statusFilter(item).id == 3,
                        'text-green-500': statusFilter(item).id == 4,
                        'text-red-500':
                          statusFilter(item).id == 5 ||
                          statusFilter(item).id == 7,
                        'text-gray-500': statusFilter(item).id == 6,
                        'text-yellow-700': statusFilter(item).id == 8,
                      }" -->
                      <!-- {{ statusFilter(item).name }} -->
                      {{ item.stateName }}
                    </label>
                    <button v-else class="text-red-500 animate-pulse">
                      {{ $t("project.detail.table.addEmail") }}
                    </button>
                  </div>
                  <div class="flex justify-between w-[200px] items-center">
                    <p
                      @click="() => $router.push('/cv/profile/' + item.cvId)"
                    >
                      {{ item.cvDate | getDate }}
                    </p>

                    <div class="flex justify-end items-center gap-3">
                      <asyncButton
                        v-if="item.stateId > 1"
                        type="button"
                        @click="
                          () =>
                            (selected = item) && $refs.preInterviewDetail.show()
                        "
                        :hiddenText="true"
                        buttonClass="w-full !px-0 !gap-0"
                        awesome="fa-solid fa-magnifying-glass"
                        containerBackground="!text-black md:!w-auto justify-center !w-full"
                        imgClass="w-5"
                        container-class="w-full md:w-auto"
                        :isTooltip="true"
                        :toolTipTitle="
                          $t('project.detail.table.interviewHistory')
                        "
                        :tooltipTop="true"
                        toolTipClass="z-50 w-[150px] !-right-4 !top-7"
                        spinnerClass="!border-t-transparent !border-black !text-black"
                        tooltipRotateClass="ml-[110px]"
                      />

                      <asyncButton
                        v-if="item.stateId == 1"
                        type="button"
                        @click="deleteItem(item)"
                        :hiddenText="true"
                        buttonClass="w-full !px-0 !gap-0"
                        awesome="fa-solid fa-trash-can text-base"
                        containerBackground="!text-black md:!w-auto justify-center !w-full"
                        imgClass="w-5"
                        container-class="w-full md:w-auto"
                        :isTooltip="true"
                        :toolTipTitle="
                          item.stateId == 1
                            ? $t('project.detail.table.deleteRessume')
                            : $t('project.detail.table.notDeleteRessume')
                        "
                        :tooltipTop="true"
                        toolTipClass="z-50 w-[130px] !-right-3 !top-7"
                        spinnerClass="!border-t-transparent !border-black !text-black"
                        tooltipRotateClass="ml-[95px]"
                      />
                    </div>
                  </div>
                </li>
              </ul>
              <tableLoader :load="load" :length="List.length" colspan="7" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <tour
      :steps="steps"
      :moduleId="4"
      v-if="!$store.state.userData.tourModuleId.includes(4)"
    />
    <pagination
      v-model="currentPage"
      :pageCount="pageCount"
      :totalRowCount="totalCount"
    />
  </div>
</template>
<script>
import pagination from "@/components/pagination.vue";
import tableLoader from "@/components/table-loader.vue";
//import card from "@/components/card.vue";
import preInterviewDetail from "./components/preInterview/detail/index.vue";
import interviewPanel from "./components/preInterview/interviewPanel";
import recommendedModal from "./components/recommendedModal.vue";
import tour from "@/components/tour.vue";

import filtering from "./components/preInterview/question/filterPanel.vue";
import interviewQuestion from "./components/preInterview/question/index.vue";
import shortListPanel from "./components/shortlist/index.vue";

import tooltip from "@/components/tooltip.vue";
import asyncButton from "@/components/button.vue";
import addEmail from "./components/addEmail.vue";

import moment from "moment";
import "moment/locale/tr";

import { projectConnect, projectInterview } from "@/networking/urlmanager";
import { projectUserTour } from "@/tourmodel/tour.js";
export default {
  name: "project-user",
  components: {
    pagination,
    tableLoader,
    //card,
    interviewPanel,
    asyncButton,
    preInterviewDetail,
    recommendedModal,
    tour,
    tooltip,
    filtering,
    interviewQuestion,
    addEmail,
    shortListPanel,
  },
  data() {
    return {
      show: false,
      isModalOver: false,
      projectUserFilter: false,
      projectUserState: [
        {
          id: 1,
          name: this.$t("state.noAction"),
          active: false,
        },
        {
          id: 2,
          name: this.$t("state.waiting"),
          active: false,
        },
        {
          id: 3,
          name: this.$t("state.started"),
          active: false,
        },
        {
          id: 4,
          name: this.$t("state.completed"),
          active: false,
        },
        {
          id: 5,
          name: this.$t("state.didNotAgree"),
          active: false,
        },
      ],
      steps: projectUserTour,
      search: "",
      currentPage: 0,
      totalCount: "",
      pageCount: 1,

      loading: false,
      load: false,

      List: [],

      projectId: 1,
      sendMail: [],
      checkedList: [],

      selected: "",
    };
  },

  methods: {
    isEmail(item) {
      const contactEmail = this.listFormatController(item?.contactEmail);
      if (contactEmail?.length > 0 && contactEmail[0]) return true;
      else return false;
    },
    getSearch() {
      this.currentPage = 0;
      this.getAll();
    },
    getAll() {
      this.load = true;
      this.List = [];
      this.axios.get(projectConnect.getUserAll, {
          params: {
            projectId: this.$route.query.id,
            page: this.currentPage,
            search: this.search,
          }
        }).then((res) => {
          const { totalCount, detail, pageCount } = res.data.data;
          this.totalCount = totalCount;
          this.pageCount = pageCount;
          this.List = detail;
          this.load = false;
        })
        .catch((err) => {
          console.log(err);
          this.authController(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getFilter(val) {
      this.totalCount = val.totalCount;
      this.pageCount = val.pageCount;
      this.List = val.detail;
    },
    createQuesion() {
      if (this.checkedList.length > 0) {
        this.verifiedMessage(
          this.$t("project.warning.startInterview"),
          this.$t("verifiedMessage.yesChance")
        ).then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.axios
              .post(
                projectInterview.createQuesion,
                {
                  projectId: this.$route.query.id,
                  list: JSON.stringify(
                    this.checkedList.map((r) => this.List[r])
                  ),
                },
                {
                  headers: {
                    Authorization: "Bareer " + this.$store.state.userData.token,
                  },
                }
              )
              .then((res) => {
                this.successMessage(res.data.message);
                this.checkedList = [];
                this.getAll();
                this.$refs.interviewPanel.toggleSidebar()
              })
              .catch((err) => {
                console.error(err);
                this.authController(err);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        });
      } else {
        this.warningMesage(this.$t("project.warning.selectUsers"));
      }
    },
    deleteItem(item) {
      this.verifiedMessage(
        "'" +
          item.fullname +
          "' " +
          this.$t("project.verifiedMessages.userRemove")
      ).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .post(
              projectConnect.delete,
              {
                rowId: item.id,
                projectName: this.$route.query.projectName,
                fullname: item.fullname,
                stateId: item.stateId,
              },
              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then((res) => {
              this.$store.commit("setProjectListDelete", this.$route.query.id);
              this.successMessage(res.data.message);
              this.getAll();
            })
            .catch((err) => {
              console.error(err);
              const errorMessage = err.response
                ? err.response.data.message
                : err.message;
              this.authController(errorMessage);
            });
        }
      });
    },
    selectAll(val) {
      if (val.target.checked) {
        for (let index = 0; index < this.List.length; index++) {
          const contactEmail = JSON.parse(this.List[index].contactEmail);
          const item = this.statusFilter(this.List[index]).id;
          if (
            (item <= 2 || item == 5) &&
            contactEmail.length > 0 &&
            contactEmail[0]
          )
            this.checkedList.push(index);
        }
      } else {
        this.checkedList = [];
      }
    },
    statusFilter(val) {
      switch (val.stateId) {
        case 1:
          return { id: 1, name: this.$t("project.detail.filterStatus.none") };
          break;
        case 2:
          let diff = 0;
          if (val.sendInterviewDate) {
            const endDate = moment(val.sendInterviewDate);
            const nowDate = moment();
            diff = endDate.diff(nowDate, "hours");
          }
          if (diff > 0)
            return {
              id: 2,
              name: this.$t("project.detail.filterStatus.waiting"),
            };
          else
            return {
              id: 5,
              name: this.$t("project.detail.filterStatus.absent"),
            };
          break;
        case 3:
          return {
            id: 3,
            name: this.$t("project.detail.filterStatus.continues"),
          };
        case 4:
          return {
            id: 4,
            name: this.$t("project.detail.filterStatus.completed"),
          };
        case 5:
          return {
            id: 6,
            name: this.$t("project.detail.filterStatus.decideGate"),
          };
        case 7:
          return {
            id: 7,
            name: this.$t("project.detail.filterStatus.insufficient"),
          };
        case 8:
          return {
            id: 8,
            name: this.$t("project.detail.filterStatus.shortListed"),
          };
        default:
          break;
      }
    },
  },
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LL");
      else return this.$t("project.detail.filterStatus.noDate");
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    projectId(newVal) {
      this.rowId = newVal;
      this.getAll();
    },
    currentPage() {
      this.getAll();
    },
    search: function (newSearch) {
      if (newSearch.length === 0) {
        this.getAll();
      }
    },
  },
};
</script>
